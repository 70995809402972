import React from 'react';
import { getColorsCategory } from '../../utils/functions';
import withPreview from '../../utils/with-preview';
import { ButtonWithLink } from '../../organisms/button/Button';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';
import ArrowRightCircle from '../../atoms/static-icons/arrow-right-circle';

const CardEtichettaCircolare = ({ data }) => {
  const product = data;
  const productDetail = product?.cardDetails;
  const categoryColor = product?.liferayFields?.taxonomyCategoryBriefs;

  return (
    <div
      data-submodule-name='card-etichetta-circolare'
      className='card-etichetta-circolare'
    >
      <span
        className='card-etichetta-circolare__category'
        style={{
          background: `var(${getColorsCategory(categoryColor).primary})`,
        }}
      ></span>
      <h2 className='card-etichetta-circolare__title'>
        {productDetail?.content?.cardTitle?.value ||
          product?.pageTitle?.value ||
          product?.detailPageTitle?.value}
        {/* Titolo del prodotto || TItolo di una Page Generic || Titolo di un blog post */}
      </h2>

      <p className='box-editorial card-etichetta-circolare__text'>
        <ContenutoHtmlInner
          rawContent={
            productDetail?.content?.cardDesc?.value ||
            product?.liferayFields?.description
          }
        />
      </p>
      <ButtonWithLink
        type='secondary'
        href={product?.link}
        text='Scopri di più'
        icon={ArrowRightCircle}
      ></ButtonWithLink>
    </div>
  );
};

export default withPreview(CardEtichettaCircolare);

import React from 'react';
import Slider from 'react-slick';
import './FasciaCardEtichettaCircolare.scss';
import '../../css/plugins/slick-custom.scss';
import { Wrapper } from '../../utils/components';
import { PrevArrow, NextArrow } from '../../molecules/arrows/arrows';
import { useWithMobileResizeListener } from '../../utils/hooks/useWithMobileResizeListener';
import Title from '../../molecules/title/title';
import { getCommonOrganismData } from '../../utils/functions';
import classNames from 'classnames';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import withPreview from '../../utils/with-preview';
import CardEtichettaCircolare from './CardEtichettaCircolare';

const FasciaCardEtichettaCircolare = ({ data, considerMenuSpace }) => {
  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    title,
    titleHeadingTag,
    titleIconId,
    marginTop,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const fasciaCardEtichettaCircolare = data;
  const cardsNumber = fasciaCardEtichettaCircolare?.products?.length;
  const disableSliderOver =
    fasciaCardEtichettaCircolare?.disableSliderOver?.value;
  const disableSliderUnder =
    fasciaCardEtichettaCircolare?.disableSliderUnder?.value;
  const isMobile = useWithMobileResizeListener(768);

  const settings = {
    infinite: false, // If is set to true, if elements < sideToShow it generetes clone tile
    dots: true,
    speed: 500,
    arrow: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    slide: '',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className='slick-dots__custom-dot'></div>,
  };

  const renderSlider = () => {
    return (
      <Slider
        {...settings}
        className='fascia-card-etichetta-circolare__carousel'
      >
        {fasciaCardEtichettaCircolare?.products?.map((product, i) => (
          <CardEtichettaCircolare key={i} data={product.value} />
        ))}
      </Slider>
    );
  };

  const renderNormalCards = () => {
    return (
      <div
        className='fascia-card-etichetta-circolare__carousel--disabled'
        data-cardsnumber={cardsNumber}
      >
        {fasciaCardEtichettaCircolare?.products?.map((product, i) => (
          <CardEtichettaCircolare key={i} data={product.value} />
        ))}
      </div>
    );
  };

  const renderMobile = () => {
    if (disableSliderUnder) {
      return renderNormalCards();
    }
    return renderSlider();
  };

  const renderDesktop = () => {
    if (disableSliderOver || cardsNumber <= 3) {
      return renderNormalCards();
    }
    return renderSlider();
  };

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-code='C-09'
      data-module-name='fascia-card-etichetta-circolare'
      className={classNames(`fascia-card-etichetta-circolare ${marginTop}`, {
        'fascia-card-etichetta-circolare--colored-background':
          backgroundColor !== 'transparent',
      })}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />

      <div className='fascia-card-etichetta-circolare__container wrapper'>
        {(title || titleIconId) && (
          <Title
            titleIconId={titleIconId}
            titleIconThickness={titleIconThickness}
            tag={titleHeadingTag}
          >
            {title}
          </Title>
        )}
        {isMobile ? renderMobile() : renderDesktop()}
      </div>
    </Wrapper>
  );
};

export default withPreview(FasciaCardEtichettaCircolare);
